import React from 'react';

import Progress from './ProgressBar/progress';
import Movie from './Movie/movie';

const SliderFive = () => (
  <>
    <Progress />
    <Movie />
  </>
);

export default SliderFive;
