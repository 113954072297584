import React from 'react';

import styles from './infoBarSecond.module.scss';

const InfoBar = () => (
  <div className={styles.wrapper}>
    <header>
      <h1 className={styles.menu__title}>
        Oferta <span>Zabiegów:</span> Tel. <span>539 930 406</span>
      </h1>
    </header>
    <ul className={styles.list}>
      <li className={styles.list__item}>
        <p className={styles.list__titleBig}>Zabiegi na twarz:</p>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
                src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-10.jpg`}
                alt="Spa"
                className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>Masaż liftingujący twarzy, szyi i dekoltu</p>
            <p className={styles.list__content}>
              Masaż typu anty-againg, który poprawia jędrność skóry, sprężystość oraz podnosi
              owal twarzy. Usprawnia krążenie krwi i limfy, dzięki czemu komórki są dotlenione
              i  odżywione.  Likwiduje  obrzęki,  przestoje  limfy  oraz  przykurcze  mięśniowe.
              Daje potężny zastrzyk energii dla skóry.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>25 min</p>
          <p className={styles.list__title}>130 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
                src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-10.jpg`}
                alt="Spa"
                className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>Peeling+ masaż liftingujący+ maska</p>
            <p className={styles.list__content}>
              Zabieg obejmujący twarz, szyję i dekolt. Wykonywany naturalnymi kosmetykami.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>45 min</p>
          <p className={styles.list__title}>180 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <p className={styles.list__titleBig}>Peeling+masaż:</p>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-10.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Rytuał Kawowy”</p>
            <p className={styles.list__content}>
              Zabieg wykonywany naturalną kawą o działaniu antycellulitowym i silnie złuszczającym
              martwy naskórek.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>55 min</p>
          <p className={styles.list__title}>200 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-11.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Rytuał Kokosowy”</p>
            <p className={styles.list__content}>Peeling całego ciała zakończony kojącym masażem.</p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>55 min</p>
          <p className={styles.list__title}>200 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <p className={styles.list__titleBig}>Peeling+maska+masaż:</p>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-12.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Kofeinowa moc”</p>
            <p className={styles.list__content}>
              Zabieg dla duszy i ciała. Rytuał wykonany z użyciem naturalnej kawy do peelingu,
              połączony z maseczką z glinki białej.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>85 min</p>
          <p className={styles.list__title}>260 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Kokosowy raj”</p>
            <p className={styles.list__content}>
              Wygładzający rytuał na ciało oraz głęboko nawilżający skórę.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>85 min</p>
          <p className={styles.list__title}>260 zł</p>
        </div>
      </li>
    </ul>
  </div>
);

export default InfoBar;
