import React, { useState, useEffect } from 'react';
import axios from 'axios';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

import SliderFirst from '../components/sliderFirst';
import SliderSecond from '../components/sliderSecond';
// import SliderThird from '../components/sliderThird';
import SliderFour from '../components/sliderFour';
import SliderFive from "../components/SliderFive";

const App = () => {
  const [startWeatherData, setStartWeatherData] = useState([]);
  const [startWeatherDataDaily, setStartWeatherDataDaily] = useState([]);
  const [startWeatherForecast, setStartWeatherForecast] = useState([]);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  // const [third, setThird] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);

  const firstTime = 56;
  const secondTime = firstTime + 28;
  // const thirdTime = secondTime - 1 + 19;
  // const fourthTime = thirdTime + 20;
  const fourthTime = secondTime + 20;
  const fifthTime = fourthTime - 1 + 27;
  const repeat = fifthTime;

  const Play = () => {
    setFirst(true);

    setTimeout(() => {
      setFirst(false);
      setSecond(true);
    }, firstTime * 1000);

    setTimeout(() => {
      // setThird(true);
      setFour(true);
    }, (secondTime - 1) * 1000);

    setTimeout(() => {
      setSecond(false);
    }, secondTime * 1000);

    // setTimeout(() => {
    //   setThird(false);
    //   setFour(true);
    // }, thirdTime * 1000);

    setTimeout(() => {
      setFour(false);
      setFive(true)
    }, fourthTime * 1000);
    setTimeout(() => {
      setFive(false);
    }, fifthTime * 1000);
  };

  useEffect(() => {
    async function fetchData() {
      const lat = 49.3648;
      const lon = 20.1059;
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },

        url: `https://api.bergregions.pl/api/v1/weather?lat=${lat}&lon=${lon}`,
      }).then((response) => {
        setStartWeatherData(response.data.currentCondition);
        setStartWeatherDataDaily(response.data.daily[0]);
        setStartWeatherForecast(response.data.today);
      });
    }
    fetchData();

    Play();
    setInterval(() => {
      Play();
    }, repeat * 1000);

    const check = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 30) {
        fetchData();
      }
    };

    setInterval(() => {
      check();
    }, 1000 * 60);
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-1.jpg`}
          alt="Hotel Kaniówka - zdjęcie"
          className={styles.background}
        />
        {first && <SliderFirst startWeatherData={startWeatherData} />}
        {second && <SliderSecond />}
        { /* {third && <SliderThird />} */ }
        {four && (
          <SliderFour
            startWeatherData={startWeatherData}
            startWeatherDataDaily={startWeatherDataDaily}
            startWeatherForecast={startWeatherForecast}
          />
        )}
        {five && <SliderFive />}
      </div>
    </>
  );
};

export default App;
