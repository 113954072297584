import React from 'react';

import styles from './progress.module.scss';

const Progress = () => (
  <div className={styles.progress}>
    <div className={styles.progress__line} />
  </div>
);

export default Progress;
