import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';
import { getIcon, getWind } from '../../../helpers/weather';

import styles from './infoBar.module.scss';
import Chart from './chart';

const InfoBar = ({ startWeatherData, startWeatherDataDaily, startWeatherForecast }) => {
  const date = new Date();

  return (
    <div className={styles.wrapper}>
      <div className={styles.location}>
        <p className={styles.location__name}>
          <span style={{ fontWeight: 'bold' }}>Aktualna pogoda: Kaniówka</span> (766m n.p.m.)
        </p>
        <div className={styles.time}>
          <p className={styles.time__time}>
            <Moment interval={1000} format="HH:mm" />
          </p>
          <p className={styles.time__date}>
            <Moment interval={1000} format="dddd, DD.MM.YYYY" />
          </p>
        </div>
      </div>
      <div className={styles.weather}>
        <div className={styles.details}>
          <img
            className={styles.details__icon}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
              date.getHours() < 21 && date.getHours() > 7 ? 'day' : 'night'
            }/${getIcon(startWeatherData.weatherCode)}`}
            alt="Ikona pogody"
          />
          <div>
            <p className={styles.details__degree}>{startWeatherData.temperatureValue}&deg;C</p>
            <p className={styles.info__paragraph}>
              Odczuwalna:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {startWeatherData.perceivedTemperature}&deg;C
              </span>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconSun}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/sunny.svg`}
              alt="Ikona słońca"
            />
            <p className={styles.info__paragraph}>
              Czas ze słońcem:&nbsp;
              <span style={{ fontWeight: 'bold' }}>
                {startWeatherDataDaily.hoursOfSun}&nbsp;godz.
              </span>
            </p>
          </div>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconWind}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/wind.svg`}
              alt="Ikona siły wiatru"
            />
            <p className={styles.info__paragraph}>
              Wiatr:&nbsp;
              <span style={{ fontWeight: 'bold' }}>
                {getWind(startWeatherData.windDirection)}&nbsp;
                {startWeatherData.windValue}km/h&nbsp;
              </span>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconHumidity}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/icon__humidity.svg`}
              alt="Ikona wilgotności"
            />
            <p className={styles.info__paragraph}>
              Wilgotność:&nbsp;
              <span style={{ fontWeight: 'bold' }}>{startWeatherData.humidityValue}%</span>
            </p>
          </div>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconPressure}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/fog.svg`}
              alt="Ikona ciśnienia"
            />
            <p className={styles.info__paragraph}>
              Ciśnienie:{' '}
              <span style={{ fontWeight: 'bold' }}>{startWeatherData.atmosphericPressure} hPa</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.forecast}>
        <p className={styles.forecast__name}>
          <span style={{ fontWeight: 'bold' }}>Prognoza na resztę dnia: Kaniówka</span> (766m
          n.p.m.)
        </p>
        <div>
          {startWeatherForecast.slice(0, 5).map((icon) => (
            <img
              className={styles.forecast__icon}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
                date.getHours() < 21 && date.getHours() > 7 ? 'day' : 'night'
              }/${getIcon(icon.weatherCode)}`}
              alt="Ikona pogody"
              key={icon.hour}
            />
          ))}
        </div>
        <div className={styles.chartBox}>
          <Chart startWeatherForecast={startWeatherForecast} />
          <div className={styles.legendBox}>
            <div className={styles.legendBox__box}>
              <div className={styles.legendBox__line} />
              <p className={styles.legendBox__paragraph}>Temperatura prognozowana</p>
            </div>
            <div className={styles.legendBox__box}>
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <p className={styles.legendBox__paragraph}>Temperatura odczuwalna</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
