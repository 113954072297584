import React from 'react';

import styles from './scroll.module.scss';

const Scroll = () => (
  <div className={styles.scroll}>
    <div className={styles.scroll__line} />
  </div>
);

export default Scroll;
