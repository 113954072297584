import React from 'react';

import styles from './infoBar.module.scss';

const InfoBar = () => (
  <div className={styles.wrapper}>
    <header>
      <h1 className={styles.menu__title}>
        Oferta <span>Masaży:</span>
      </h1>
    </header>
    <ul className={styles.list}>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Relaksacyjny”</p>
            <p className={styles.list__content}>
              Masaż całego ciała gorącym i aromatycznym olejkiem.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>55 min</p>
          <p className={styles.list__title}>160 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-2.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Masaż pleców”</p>
            <p className={styles.list__content}>Relaksacyjny masaż częściowy.</p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>30 min</p>
          <p className={styles.list__title}>120 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-3.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Sycylijski”</p>
            <p className={styles.list__content}>
              Intensywny, energetyczny masaż całego ciała, połączony z elementami stretchingu i
              punktów spustowych.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>55 min</p>
          <p className={styles.list__title}>180 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-8.jpeg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Magia dotyku”</p>
            <p className={styles.list__content}>
              Niezwykle odprężająca ceremonia masażu całego ciała ciepłym olejkiem.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>75 min</p>
          <p className={styles.list__title}>220 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-9.jpeg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Kokosowy”</p>
            <p className={styles.list__content}>Relaksacyjny masaż gorącym olejkiem kokosowym.</p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>55 min</p>
          <p className={styles.list__title}>180 zł</p>
        </div>
      </li>
      <li className={styles.list__itemNone}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-6.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Gorącymi kamieniami”</p>
            <p className={styles.list__content}>
              Masaż wykonywany ze specjalnie odlanych kamieni wulkanicznych.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>80 min</p>
          <p className={styles.list__title}>180 zł</p>
        </div>
      </li>
      <li className={styles.list__itemNone}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-6.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>„Gorącymi kamieniami”</p>
            <p className={styles.list__content}>
              Masaż wykonywany ze specjalnie odlanych kamieni wulkanicznych.
            </p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>80 min</p>
          <p className={styles.list__title}>180 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-5.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>Peeling Kawowy</p>
            <p className={styles.list__content}>Peeling kawowy całego ciała.</p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>30 min</p>
          <p className={styles.list__title}>130 zł</p>
        </div>
      </li>
      <li className={styles.list__item}>
        <div className={styles.list__box}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-7.jpg`}
              alt="Spa"
              className={styles.list__image}
            />
          </div>
          <div>
            <p className={styles.list__title}>Peeling Kokosowy</p>
          </div>
        </div>
        <div>
          <p className={styles.list__title}>30 min</p>
          <p className={styles.list__title}>130 zł</p>
        </div>
      </li>
    </ul>
  </div>
);

export default InfoBar;
