import React from 'react';

import styles from './infoBarSmall.module.scss';

const InfoBarSmall = () => (
  <div className={styles.info}>
    <p className={styles.info__paragraph}>
      <span>Witamy w Kaniówka Ski Resort</span>
    </p>
    <p className={styles.info__paragraph}>
      <span>Welcome to Kaniówka Ski Resort</span>
    </p>
    <p className={styles.info__paragraph}>
      <span>Witamy w Kaniówka Ski Resort</span>
    </p>
    <p className={styles.info__paragraph}>
      <span>Śniadania</span> serwujemy w godzinach od <span>8:30</span> do <span>10:00</span>
    </p>
    {/* <p className={styles.info__paragraph}>
      <span>Obiadokolacje</span> serwujemy w godzinach od <span>15:00</span> do <span>17:00</span> -
      zamów w recepcji
    </p> */}
    <p className={styles.info__paragraph}>
      <span>Basen</span> i <span>jacuzzi</span> czynne w godzinach od <span>10:00</span> do{' '}
      <span>21:00</span>
    </p>
    <p className={styles.info__paragraph}>
      <span>Sauna</span> czynna w godzinach od <span>17:00</span> do <span>21:00</span>
    </p>
    <p className={styles.info__paragraph}>
      <span>Zapraszamy</span> na zabiegi <span>Spa</span>
    </p>
  </div>
);

export default InfoBarSmall;
