import React from 'react';

import ReactApexChart from 'react-apexcharts';

const ChartBox = ({ startWeatherForecast }) => {
  const options = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#1979D4', '#1979D4'],
    markers: {
      size: 5,
    },
    stroke: {
      curve: 'smooth',
      width: 5,
      dashArray: [0, 5],
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#323232',
          fontSize: '0.8vw',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
        },
        offsetY: 5,
      },
      axisBorder: {
        show: true,
        color: '#7e8d94',
        height: 1,
        width: '100%',
      },
    },
    yaxis: {
      tickAmount: 4,
      max: (value) => value + 1,
      min: (value) => value - 1,
      labels: {
        show: true,
        style: {
          colors: '#323232',
          fontSize: '0.8vw',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
        },
        formatter: (value) => `${value.toFixed(0)}\xB0C`,
      },
      axisBorder: {
        show: true,
        color: '#7e8d94',
        height: '100%',
        width: 1,
      },
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 14,
      },
    },
    noData: {
      text: 'Loading...',
    },
  };

  const temperature0 = startWeatherForecast?.slice(0, 1).map((item) => item.temperatureValue);
  const temperature1 = startWeatherForecast?.slice(1, 2).map((item) => item.temperatureValue);
  const temperature2 = startWeatherForecast?.slice(2, 3).map((item) => item.temperatureValue);
  const temperature3 = startWeatherForecast?.slice(3, 4).map((item) => item.temperatureValue);
  const temperature4 = startWeatherForecast?.slice(4, 5).map((item) => item.temperatureValue);

  const perceivedTemperature0 = startWeatherForecast
    ?.slice(0, 1)
    .map((item) => item.perceivedTemperatureValue);
  const perceivedTemperature1 = startWeatherForecast
    ?.slice(1, 2)
    .map((item) => item.perceivedTemperatureValue);
  const perceivedTemperature2 = startWeatherForecast
    ?.slice(2, 3)
    .map((item) => item.perceivedTemperatureValue);
  const perceivedTemperature3 = startWeatherForecast
    ?.slice(3, 4)
    .map((item) => item.perceivedTemperatureValue);
  const perceivedTemperature4 = startWeatherForecast
    ?.slice(4, 5)
    .map((item) => item.perceivedTemperatureValue);

  const hour0 = startWeatherForecast?.slice(0, 1).map((item) => item.hour);
  const hour1 = startWeatherForecast?.slice(1, 2).map((item) => item.hour);
  const hour2 = startWeatherForecast?.slice(2, 3).map((item) => item.hour);
  const hour3 = startWeatherForecast?.slice(3, 4).map((item) => item.hour);
  const hour4 = startWeatherForecast?.slice(4, 5).map((item) => item.hour);

  const series = [
    {
      name: 'forecast',
      data: [
        {
          x: `${hour0}:00`,
          y: temperature0,
        },
        {
          x: `${hour1}:00`,
          y: temperature1,
        },
        {
          x: `${hour2}:00`,
          y: temperature2,
        },
        {
          x: `${hour3}:00`,
          y: temperature3,
        },
        {
          x: `${hour4}:00`,
          y: temperature4,
        },
      ],
    },
    {
      name: 'forecast-perceived',
      data: [
        {
          x: `${hour0}:00`,
          y: perceivedTemperature0,
        },
        {
          x: `${hour1}:00`,
          y: perceivedTemperature1,
        },
        {
          x: `${hour2}:00`,
          y: perceivedTemperature2,
        },
        {
          x: `${hour3}:00`,
          y: perceivedTemperature3,
        },
        {
          x: `${hour4}:00`,
          y: perceivedTemperature4,
        },
      ],
    },
  ];

  return (
    <div style={{ width: '68vw', height: '27vh' }} id="chart">
      <ReactApexChart options={options} series={series} type="line" width="100%" height="100%" />
    </div>
  );
};

export default ChartBox;
