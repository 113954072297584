import React from 'react';

import Scroll from './Scroll/scroll';
import Progress from './ProgressBar/progress';
import InfoBar from './InfoBar/infoBar';
import InfoBarSecond from './InfoBarSecond/infoBarSecond';

const SliderSecond = () => (
  <>
    <Scroll />
    <Progress />
    <div>
      <InfoBar />
      <InfoBarSecond />
    </div>
  </>
);

export default SliderSecond;
