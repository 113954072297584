import React from 'react';

import styles from './slider.module.scss';

const Slider = () => (
  <div>
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-2.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-witamy.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-pokoj.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-sniadanie.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    {/* <img --- OBIADY ---
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-4.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    /> */}
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-12.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-6.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-7.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-2.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-3.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-4.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-5.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-6.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-7.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-8.jpeg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-9.jpeg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-10.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-11.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/ds-kaniowka-spa-12.jpg`}
      alt="Hotel Kaniówka - zdjęcie"
      className={styles.spa}
    />
  </div>
);

export default Slider;
