import React from 'react';

import ProgressBar from './ProgressBar/progress';
import InfoBar from './InfoBar/infoBar';
import Slider from './Slider/slider';
import InfoBarSmall from './InfoBarSmall/infoBarSmall';

const SliderFirst = ({ startWeatherData }) => (
  <>
    <ProgressBar />
    <InfoBar startWeatherData={startWeatherData} />
    <Slider />
    <InfoBarSmall />
  </>
);

export default SliderFirst;
